export default function scrollIntoView(
	elem: HTMLElement,
	options?: ScrollOptions,
) {
	const top = elem.getBoundingClientRect().top + window.scrollY;
	window.scrollTo({
		...options,
		top,
	});
}
