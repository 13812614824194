import { NavLink } from "@remix-run/react";
import { FormattedMessage } from "react-intl";

import type * as typo3 from "~/modules/typo3/schema";
import { cn } from "~/utils/cn";
import { PersonMenuThumb } from "~/components/shared/PersonMenuThumb";

function RelatedPersonsTitle({ article }: { article: typo3.Article }) {
	switch (article.articleType) {
		case "projekt":
		case "lehrangebot":
			return <FormattedMessage id="relatedPersonsTitle.teachers" />;
		default:
			return <FormattedMessage id="relatedPersonsTitle.default" />;
	}
}

function RelatedPersonsEssentialsItemContent({
	person,
}: {
	person: typo3.RelatedPerson;
}) {
	const content = (
		<>
			<div className="image-wrapper">
				<div className="img-menu-thumb">
					{person.image && (
						<PersonMenuThumb personImage={person.image} />
					)}
				</div>
			</div>

			<div className="snippet-info-wrapper">
				<div className="snippet-title">{person.fullName}</div>
				{person.job && (
					<div className="snippet-sub-info">
						{person.job}
						<br />
						{person.info ? ` (${person.info})` : ""}
					</div>
				)}
			</div>
		</>
	);

	return (
		<li
			className={cn({
				"snippet-with-image": person.image,
				"snippet-with-link": person.detailsUrl,
			})}
		>
			{person.detailsUrl ? (
				<NavLink to={person.detailsUrl}>{content}</NavLink>
			) : (
				content
			)}
		</li>
	);
}

export default function RelatedPersonsEssentials({
	article,
}: {
	article: typo3.Article;
}) {
	return (
		<div className="essential-item essential-foldout-section module">
			<div className="content-head element group/heading mode-label">
				<div className="title-wrapper">
					<h2 className="content-title">
						<RelatedPersonsTitle article={article} />
					</h2>
				</div>
			</div>

			<div className="link-list">
				<ul className="contents">
					{article.relatedPersons.map((person) => (
						<RelatedPersonsEssentialsItemContent
							key={person.uid}
							person={person}
						/>
					))}
				</ul>
			</div>
		</div>
	);
}
