import { FormattedMessage } from "react-intl";
import { Link } from "@remix-run/react";

import type * as typo3 from "~/modules/typo3/schema";
import useSpecialPageUrl from "~/hooks/useSpecialPageUrl";
import Return from "~/components/icons/Return";
import { renderProjectType } from "~/components/shared/ProjectType";

type ProjectEssentialsTitleProps = {
	article: typo3.Project;
};

export default function ProjectEssentialsTitle({
	article,
}: ProjectEssentialsTitleProps) {
	const projectsPageUrl = useSpecialPageUrl("projectsPage");
	const parentArticle = article.articleParent;
	const isStudyProject = article.projectType !== "0";
	const backUrl = isStudyProject
		? (article.articleParent?.detailsUrl ?? projectsPageUrl)
		: null;
	const label = renderProjectType(article);

	return (
		<>
			<div className="button-return-container">
				<button
					onClick={() => window.history.back()}
					className="button--blank icon-button"
				>
					<Return />

					<span>
						<FormattedMessage
							id={
								parentArticle
									? "projectEssentialsTitle.backToGroupProject"
									: "projectEssentialsTitle.backToProjects"
							}
						/>
					</span>
				</button>
			</div>

			<div className="inner">
				<div className="label head top">{label}</div>
				<h2 className="title">{article.title}</h2>
				<div className="meta bottom label">{article.subtitle}</div>
			</div>
		</>
	);
}
