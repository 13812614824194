import { Link } from "@remix-run/react";

import { cn } from "~/utils/cn";
import type * as typo3 from "~/modules/typo3/schema";
import ContentHead from "~/components/shared/ContentHead";
import { PersonMenuThumb } from "~/components/shared/PersonMenuThumb";
import type { PersonsPluginListProps } from "~/components/plugins/Persons";

function EssentialsFoldoutPersonContent({
	person,
}: {
	person: typo3.PersonCard;
}) {
	const content = (
		<>
			<div className="image-wrapper">
				<div className="img-menu-thumb">
					{person.image && (
						<PersonMenuThumb personImage={person.image} />
					)}
				</div>
			</div>

			<div className="snippet-info-wrapper">
				<div className="snippet-title">{person.fullName}</div>
				<div className="snippet-sub-info">
					{person.job}
					<br />
					{person.info ? ` (${person.info})` : ""}
				</div>
			</div>
		</>
	);

	return (
		<li
			className={cn({
				"snippet-with-image": person.image,
				"snippet-with-link": person.detailsUrl,
			})}
		>
			{person.detailsUrl ? (
				<Link to={person.detailsUrl}>{content}</Link>
			) : (
				content
			)}
		</li>
	);
}

export function EssentialsFoldout({ data, persons }: PersonsPluginListProps) {
	return (
		<div className="essential-item essential-foldout-section module">
			<ContentHead mode="label" data={data} />
			<ul className="link-list">
				{persons.map((person, index) => (
					<EssentialsFoldoutPersonContent
						key={index}
						person={person}
					/>
				))}
			</ul>
		</div>
	);
}
