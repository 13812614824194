import { useMemo } from "react";
import { useIntl } from "react-intl";

import { cn } from "~/utils/cn";
import type * as typo3 from "~/modules/typo3/schema";
import { isContentElementWithHeading } from "~/modules/typo3/schema";
import { getTypo3BackendUrl } from "~/modules/typo3/backend";
import ScrollContent from "~/components/shared/ScrollContent";
import ScrollContentMain from "~/components/shared/ScrollContentMain";
import BodyText from "~/components/shared/BodyText";
import ContentElement from "~/components/content-elements/ContentElement";
import Image from "~/components/shared/Image";
import Calendar from "~/components/shared/calendar/Calendar";
import EssentialsOnPageNav from "~/components/shared/EssentialsOnPageNav";
import AppLayout from "~/components/shared/AppLayout";
import EditInCMSButton from "~/components/EditInCMSButton";
import EmailLink from "~/components/shared/EmailLink";
import { useGlobal } from "~/context/global";

type PersonLayoutProps = {
	page: typo3.Response;
	person: typo3.Person;
};

export default function PersonLayout({ page, person }: PersonLayoutProps) {
	const intl = useIntl();
	const jumpMenuItems =
		person.contentElements
			?.filter(isContentElementWithHeading)
			.map((contentElement) => ({
				anchor: `c${contentElement.id}`,
				label: contentElement.content.header,
			})) ?? [];

	const editLink = useMemo(() => {
		const url = getTypo3BackendUrl("/typo3/record/edit");
		url.searchParams.append(
			`edit[tx_nnbuhaarticlesandpersons_domain_model_person][${person.uid}]`,
			"edit",
		);
		return url.toString();
	}, [person.uid]);

	const hasMainContent = person.content.trim() !== "";
	const hasMainContentElements = Boolean(person.contentElements?.length);
	const hasMainColumn = hasMainContent || hasMainContentElements;

	const pageTitle = (
		<header className="page-title essential-item essentials-header stick">
			<div className="inner">
				<h2 className="title">{person.fullName}</h2>
				<div className="meta bottom">
					{person.job}
					<br />
					{person.info ? ` (${person.info})` : ""}
				</div>
			</div>
		</header>
	);
	const { isMobile } = useGlobal();
	return (
		<AppLayout
			layout="page"
			className={cn({
				"person-with-image": person.image,
				"layout-with-main-column": hasMainColumn,
			})}
		>
			<EditInCMSButton link={editLink} />

			<ScrollContentMain>
				<div className="column col-main">
					<div
						className={
							"main-content" +
							(isMobile ? " jump-to-nav mobile" : "")
						}
					>
						{pageTitle}

						{person.content.trim() !== "" && (
							<div className="module text">
								<div className="text-container">
									<BodyText
										dangerouslySetInnerHTML={{
											__html: person.content,
										}}
									/>
								</div>
							</div>
						)}

						{person.contentElements?.map((contentElement) => (
							<ContentElement
								key={contentElement.id}
								data={contentElement}
								size="full"
							/>
						))}
					</div>
				</div>

				<div className="column col-aside right">
					{/* TODO: This calendar should be based on the person! */}
					<Calendar sectionDescription={page.meta.title} />
				</div>

				<div className="column col-aside left">
					<div className="essentials module">
						<ScrollContent className="essentials-inner">
							{pageTitle}

							<div className="essentials-content">
								{person.image && (
									<div className="essential-item person-media-wrapper module desktop-only">
										<figure>
											<Image
												image={person.image}
												widthPercentage={30}
												widthPercentageMobile={100}
											/>
										</figure>
									</div>
								)}
								{jumpMenuItems.length > 0 && (
									<div className="page-jumper-nav essential-item essential-nav">
										<EssentialsOnPageNav
											items={jumpMenuItems}
										/>
									</div>
								)}

								<div className="essential-item essentials-group module">
									{(person.address ||
										person.phone ||
										person.email) && (
										<div className="essential-item">
											<div className="content-head mode-label element">
												<h4 className="content-title">
													{intl.formatMessage({
														id: "personPage.contact",
													})}
												</h4>
											</div>

											<div className="essential-item-content link-list jump-to-nav desktop">
												{person.address && (
													<p>{person.address}</p>
												)}
												{person.phone && (
													<p>{person.phone}</p>
												)}
												{person.email && (
													<EmailLink
														emailAddress={
															person.email
														}
													/>
												)}
											</div>
										</div>
									)}

									{person.context && (
										<div className="essential-item module">
											<div className="essential-item-content">
												<BodyText
													dangerouslySetInnerHTML={{
														__html: person.context,
													}}
												/>
											</div>
										</div>
									)}
								</div>
							</div>
						</ScrollContent>
					</div>
				</div>
			</ScrollContentMain>

			{/* TODO: projects of a person (solr/works-grid) */}
		</AppLayout>
	);
}
